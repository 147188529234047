import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import Button from "components/Button";
import InputText from "components/InputText";
import Wrapper from "components/Wrapper";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useTranslation } from "hooks/useTranslation";
import { useUser } from "hooks/useUser";
import { FieldLocale } from "utils/common/locale";
import { routes } from "utils/routes";
import SEO from "components/SEO";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Login page wrapper
 * Just to allow useContext usage inside
 */
const LoginPage: React.SFC<Props> = ({ pageContext }) => {
  return <LoginForm pagePath={pageContext.pagePath} />;
};

/**
 * Handle login process
 */
function LoginForm({ pagePath }: { pagePath: string }) {
  const { formatMessage } = useTranslation();
  const { errors, loading, user, login } = useUser();

  // State variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Effects
  useEffect(() => {
    if (!loading && user) {
      navigate(routes.account);
    }
  }, [loading, user]);

  /**
   * Execute login
   * In case of error populate the error box
   */
  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    await login(email, password);
  };

  const canLogin = email.length > 0 && password.length > 0;

  return (
    <Container>
      <Wrapper size="small">
        <SEO
          title="Login"
          description={formatMessage({ id: "SEO.loginDescription" })}
          link={[
            { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
          ]}
        />
        <Login onSubmit={handleLogin}>
          <Title>Login</Title>

          <InputText
            siqatrib="email"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <InputText
            type="password"
            name="password"
            autocomplete="current-password"
            placeholder="Password"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />

          {errors && errors.length > 0 ? (
            <ErrorBox>
              {formatMessage({
                id: "error.usernameOrPasswordWrong",
                defaultMessage: "Username or password wrong",
              })}
            </ErrorBox>
          ) : null}
          <ActionContainer>
            <Button
              type="submit"
              disabled={!canLogin}
              loading={loading && canLogin}
            >
              Login
            </Button>
            <LinkContainer>
              <Link to={routes.recoverPassword}>
                <FormattedMessage id="label.forgotPassword" />
              </Link>
            </LinkContainer>
          </ActionContainer>
        </Login>
        <Signup>
          <Title>
            <FormattedMessage id="label.createAccount" />
          </Title>
          <Description>
            <FormattedMessage id="label.createAccountDescription" />
          </Description>
          <LinkButtonContainer>
            <Link to={routes.signup}>
              <FormattedMessage id="label.signup" />
            </Link>
          </LinkButtonContainer>
        </Signup>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
  ${Wrapper} {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
`;

const Login = styled.form`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 80px 50px 0;
  border-right: 1px solid #ededed;
  @media (max-width: 1000px) {
    padding: 50px 40px 50px 20px;
  }
  @media (max-width: 800px) {
    padding: 50px 20px 50px 0px;
  }
  @media (max-width: 700px) {
    border-right: none;
    border-bottom: 1px solid #ededed;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 0 0 40px 0;
  }
`;

const Signup = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 0 50px 80px;
  @media (max-width: 1000px) {
    padding: 50px 20px 50px 40px;
  }
  @media (max-width: 800px) {
    padding: 50px 0px 50px 20px;
  }
  @media (max-width: 700px) {
    border-right: none;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 40px 0 0 0;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: #977b2b;
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const LinkContainer = styled.div`
  background-color: none;
  border: none;
  font-family: soleil, sans-serif;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.04em;
  white-space: nowrap;

  a {
    color: #333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 14px;
  color: #706f6f;
  line-height: 1.8em;
  letter-spacing: 0.06em;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const LinkButtonContainer = styled.div`
  margin-top: 30px;
  @media (max-width: 400px) {
    text-align: center;
  }
  a {
    display: inline-block;
    background-color: #977b2b;
    outline: inherit;
    font-size: 14px;
    letter-spacing: 0.16em;
    font-weight: 600;
    width: 100%;
    max-width: 220px;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    transition: 0.3s all;
    color: #fff;
    width: 100%;
    height: 50px;
    text-align: center;
    text-decoration: none;
    padding-top: 13px;
    &:hover {
      cursor: pointer;
      background-color: #806724;
    }
  }
`;

const ErrorBox = styled.div`
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

export default LoginPage;
